"use client"

import InputDate from "@/components/crm/Modal/InputDate"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import * as React from "react"
import { TimePicker } from "./time-picker"

interface Props {
  date: Date,
  setDate: (date: Date) => void
  elementTigger: React.ReactNode
  align?: 'center' | 'start' | 'end'
  classNameContent?: string
}

export function DateTimePicker({ date, setDate, elementTigger, classNameContent, align = 'center' }: Props) {
  const [isInvalidDate, setInvalidDate] = React.useState('')

  return (
    <Popover>
      <PopoverTrigger asChild>{elementTigger}</PopoverTrigger>
      <PopoverContent align={align} className={cn("w-72", classNameContent)}>
        <DateTimePickerContent className="flex flex-col gap-2" date={date} setDate={setDate} setInvalidDate={setInvalidDate} />
      </PopoverContent>
    </Popover>
  )
}


export function DateTimePickerContent({
  date,
  setDate,
  setInvalidDate,
  className = '',
  classNameTimePicker = '',
  hiddenOptions = {},
  labels = true,
  hiddenInputHour = false,
  hiddenInputDate = false,
  classNameInputDate = '',
  errorHandler = true,
  required = true
}) {

  const handleChangeDate = (newDate: Date) => {
    if (!newDate) return setDate(date)

    let useDate = date || new Date();

    const [hours, minutes, seconds] = [useDate.getHours(), useDate.getMinutes(), useDate.getSeconds()]

    newDate.setHours(hours)
    newDate.setMinutes(minutes)
    newDate.setSeconds(seconds)

    setDate(newDate)
  }

  return <div className={cn(className)}>
    {
      !hiddenInputDate && <InputDate
        date={date}
        errorHandler={errorHandler}
        className={classNameInputDate}
        handleInvalidDate={setInvalidDate}
        required={false}
        setDate={handleChangeDate} />
    }
    {
      !hiddenInputHour && (
        <TimePicker
          className={classNameTimePicker}
          setDate={setDate}
          labels={labels}
          date={date}
          hidden={{ seconds: true, ...hiddenOptions }}
        />
      )
    }
  </div>
}

export function DateTimePickerWithHandleError({
  date,
  setDate,
  hiddenInputDate = false,
  handleSetError,
  hiddenInputHour = false
}: {
  date: Date,
  setDate: (value: Date) => void,
  hiddenInputDate?: boolean,
  handleSetError?: (value: boolean) => void,
  hiddenInputHour?: boolean
}) {
  const [isInvalidDate, setInvalidDate] = React.useState('')

  return <DateTimePickerContent
    hiddenInputDate={hiddenInputDate}
    errorHandler={false}
    className="flex gap-2 items-start"
    labels={false}
    date={date}
    hiddenInputHour={hiddenInputHour}
    setDate={setDate}
    setInvalidDate={(value) => {
      handleSetError && handleSetError(value)
      setInvalidDate(value)
    }}
  />
}