"use client"

import { Label } from "@/components/ui/label"
import { cn } from "@/lib/utils"
import dayjs from "dayjs"
import * as React from "react"
import { TimePickerInput } from "./time-picker-input"

interface TimePickerDemoProps {
  date: Date | undefined
  labels?: boolean
  className?: string
  hidden?: {
    hour?: boolean
    minutes?: boolean
    seconds?: boolean
  }
  setDate: (date: Date | undefined) => void
}

export function TimePicker({
  date,
  setDate,
  labels = true,
  hidden = {
    hour: false,
    minutes: false,
    seconds: false,
  },
  className = ''
}: TimePickerDemoProps) {
  const { hour, minutes, seconds } = hidden
  const minuteRef = React.useRef<HTMLInputElement>(null)
  const hourRef = React.useRef<HTMLInputElement>(null)
  const secondRef = React.useRef<HTMLInputElement>(null)

  const dateToUse = date || dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .toDate();

  return (
    <div className={cn("flex items-end  gap-2", className)}>
      {!hour && (
        <div className="grid gap-1 text-start">
          {
            labels && <Label htmlFor="hours" className="text-xs">
              Hora
            </Label>
          }

          <TimePickerInput
            picker="hours"
            date={dateToUse}
            setDate={setDate}
            ref={hourRef}
            onRightFocus={() => minuteRef.current?.focus()}
          />
        </div>
      )}
      {!minutes && (
        <div className="grid gap-1 text-start">
          {
            labels && <Label htmlFor="minutes" className="text-xs">
              Minutos
            </Label>
          }
          <TimePickerInput
            picker="minutes"
            date={dateToUse}
            setDate={setDate}
            ref={minuteRef}
            onLeftFocus={() => hourRef.current?.focus()}
            onRightFocus={() => secondRef.current?.focus()}
          />
        </div>
      )}
      {!seconds && (
        <div className="grid gap-1 text-start">
          {
            labels && <Label htmlFor="seconds" className="text-xs">
              Segundos
            </Label>
          }
          <TimePickerInput
            picker="seconds"
            date={dateToUse}
            setDate={setDate}
            ref={secondRef}
            onLeftFocus={() => minuteRef.current?.focus()}
          />
        </div>
      )}
    </div>
  )
}
