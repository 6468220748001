import { Dispatch, SetStateAction, useCallback, useState } from "react";

export function useToggle<T = boolean>(defaultValue?: T): [T, (value?: any) => void, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(defaultValue ?? false as T);

  const toggle = (value?: any) =>
    setValue((prevValue) => typeof value !== "undefined" && typeof value === "boolean" ? (value as T) : (!prevValue as T));

  return [value, toggle, setValue];
}
